
@keyframes spinner {
    to {transform: rotate(360deg);}
  }
   
  .pre {
    /* content: ''; */
    box-sizing: border-box;
    position: absolute;
    top: 89.6%;
    left: 50%;
    width: 35px;
    height: 35px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #FFB900 !important;
    border-top-color: #7FBA00 !important;
    border-top-color: #07d !important;
    animation: spinner 1s linear infinite;
  }

  .preeee{
    box-sizing: border-box;
    position: absolute;
    top: 92%;
    text-align: center;
    /* left: 50%; */
    width: 100%;
    height: 30px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    font-family: 'Roboto', sans-serif;
    /* margin-top: -15px;
    margin-left: -15px; */
    /* border: 1px solid #000; */
  }