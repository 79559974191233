

.prod_ucts_{
    margin-top: 5em;
}

.pldkk{
    display: flex !important;
    justify-content: center !important;
}

.futa{
    font-size: 11px;
    text-align: center;
    color: #333;
    position: fixed;
    bottom: 3em;
    user-select: none;
}

tr{
    border-bottom: 2px solid #eee !important;
}

td{
    padding: 1em !important;

}

@media (max-width:991px) {
    #quer{
        display: none !important;
    }
}