
.logo_sharepoint_container{
    width: 100%;
    height: 8vh !important;
    /* border: 1px solid #000; */
    background: #fff;
    border-bottom: 1px solid #ededed;
}

.logo_share_point_i_m_g_{
    height: 60px;
    margin-left: 10rem;
    padding-top: 7px;

}

.f_video_player_{
    width: 100% !important;
    /* height: 82vh !important; */
    /* border: 1px solid red; */
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -10;
    min-width: 100% !important; 
    min-height: 100% !important;
  
}

.hey{
    margin-top: 3.7em;
}

.land_pg_p_ik{
    vertical-align: middle !important;
    border-style: none;
    height: 90px;
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
    /* position: relative; */
    top: 3em;
    cursor: grab;
}

.land_p_g_txt_contnt{
    text-align: center !important;
}

.w_el_cm_txt_{
    color: #fff;
    /* color: #ea3e23; */
    font-size: 3em;
    /* font-family: "Ubuntu", sans-serif; */
    font-family: "Roboto", sans-serif;
    margin-top: 1em;
}

.co_pilo_{
    font-weight: bold;
    font-family: "Ubuntu", sans-serif;
    font-size: 4em;
    user-select: none !important;
}

.e_ffec_t{
    color: #07d;
    font-family: "Roboto", sans-serif;
    font-size: 24px;

}

.btn_wrappe_r{
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
    margin-top: 3em !important;
}

.bbtnn{
    transition: all .1s linear;
}

.bbtnn:hover{
    color: #fff;
    background: #a9301e !important;
    
}

.bbtnn:active{
    padding: 21px 44px !important;
}

.btm{
    font-size: 11.6px;
    text-align: center !important;
}